import React from 'react';

import { Box, Stat } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Image, Layout } from 'antd';

const { Content } = Layout;

type AppTemplateProps = {
  headerTitle: string;
  headerBody?: React.ReactElement;
};

export const AppTemplate: React.FC<AppTemplateProps> = ({ headerTitle, headerBody, children }) => {
  return (
    <Layout
      style={{
        height: '100vh',
        backgroundImage: `url(/bg.jpg)`,
        backgroundSize: 'cover',
        width: '100vw',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      <OuterContainer>
        <Paper>
          <ContentContainer>
            <Box p={1} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Image
                preview={false}
                width='100%'
                src='1.png'
                style={{ borderRadius: '24px' }}
              />
              <StatStyled>{headerBody}</StatStyled>
            </Box>
            {children}
          </ContentContainer>
        </Paper>
      </OuterContainer>
    </Layout>
  );
};

export const StatStyled = styled(Stat)`
  margin-top: 8px;
  @media (max-width: 425px) {
    & > dl > dt {
      font-size: var(--chakra-fonts-body);
    }

    & > dl > dd {
      font-size: var(--chakra-fonts-body);
    }
  }

  @media (max-width: 350px) {
    & > dl > dt {
      font-size: var(--chakra-fonts-body);
    }

    & > dl > dd {
      font-size: var(--chakra-fonts-body);
    }
  }
`;

const OuterContainer = styled(Content)`
  min-height: 100%;
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled(Box)`
  display: flex;
  margin: 0 auto;
  //width: 340px;
  padding: 10px 10px;
  flex-direction: column;
  box-shadow: 7px 4px 14px 2px rgba(34, 60, 80, 0.2);
  background: white;
  border-radius: 28px;

  @media (max-width: 425px) {
    width: 95%;
  }
`;

const Paper = styled(Box)`
  height: 100%;
  padding: var(--chakra-space-6);

  @media (max-width: 425px) {
    padding: var(--chakra-space-1);
  }
`;
