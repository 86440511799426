import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  StatLabel,
} from '@chakra-ui/react';
import { AppTemplate, StatStyled } from '../../shared/ui/AppTemplate';
import { checkPhoneFriend, sendLeadToVakasFriend, sendLeadToVakasRefereal } from '../../shared/api/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../shared/enums/routes';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Typography } from 'antd';

function isNumeric(value: string) {
  if (value === '') {
    return true;
  }

  return /^-?\d+$/.test(value);
}

const firebaseConfig = {
  apiKey: 'AIzaSyAODnA4EfNRBeGgA4BNoEb3PYJ5TFcgAlY',
  authDomain: 'missis-laser.firebaseapp.com',
  projectId: 'missis-laser',
  storageBucket: 'missis-laser.appspot.com',
  messagingSenderId: '514174044584',
  appId: '1:514174044584:web:3a7576b39c7a543c6ab59d',
  measurementId: 'G-QTNN569ZLV',
};

const utm_campaign = ' (БП990Подруга)';
const utm_medium = ' (БП990Подруга)';

export const Home = observer(() => {
  const [error, setError] = useState<boolean>();
  const [errorText, setErrorText] = useState<string | null>(null);
  //* ==================
  const [username, setUserName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  //* ==================
  const [friend_name, setFriend_Name] = useState<string>('');
  const [friend_phone, setFriend_Phone] = useState<string>('');
  const [city, setCity] = useState<string>('');

  const history = useHistory();

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  React.useEffect(() => {
    logEvent(analytics, window.location.hostname);
  }, [analytics]);

  const onSubmit = async () => {
    try {
      await checkPhoneFriend({ phone: '+7' + friend_phone, clinic_id: 0 }).then(() => {
        setError(true);
        setErrorText('Номер подруги уже есть в базе');
      });
    } catch (error) {
      try {
        await sendLeadToVakasFriend({
          friend_name: username,
          friend_phone: '+7' + phone,
          city,
          name: friend_name,
          phone: '+7' + friend_phone,
          utm_source: window.location.hostname,
          utm_campaign,
          utm_medium,
        })
          .then(async () => {
            await sendLeadToVakasRefereal({
              city,
              name: username,
              phone: '+7' + phone,
              utm_source: window.location.hostname,
              utm_campaign,
              utm_medium,
            });
          })
          .then(() => history.push(Routes.Success));
      } catch (error) {
        console.log(error);
        history.push(Routes.Error);
      }
    }
  };

  return (
    <AppTemplate
      headerTitle='Тест драйв'
      headerBody={
        <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
          Приветствуем, уважаемый клиент! И поздравляем Вас! <br />
          Мы даем Вам доступ к участию в ограниченной закрытой акции для наших давних клиентов!
          <br />
          🏆 Вы получаете возможность посетить процедуру лазерной эпиляции зон: <br />
          "Бикини + подмышки" всего за 990₽, вместо 3100₽!
          <br />
          Предложение действует только до 30 апреля!
          <br />
          Не упустите возможность с максимальной выгодой сделать комфортную эпиляцию самых популярных зон! 🤩
          <br /> 😘 Готовимся к жаркому лету в лучшее время за лучшие цены!
          <br />
        </StatLabel>
      }
      //headerBody={`Акция для новых клиентов только в Январе!\nЛазерная эпиляция зон Бикини + Подмышки за 990 руб вместо 2300 руб! Пока есть возможность - оставляйте заявку и приходите на процедуру! Ждём Вас!`}
    >
      <Box
        p={0}
        overflowY='auto'
        flexGrow={1}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography.Title level={5} style={{ textAlign: 'center' }}>
          Для того, чтобы воспользоваться данным предложением, необходимо сейчас забронировать его за собой, для этого
          укажите:
        </Typography.Title>
        <Stack spacing='2rem'>
          <Stack spacing='1rem' alignItems='center'>
            <InputGroup style={{ maxWidth: '340px' }}>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Имя'
                value={username}
                onChange={e => {
                  setUserName(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup style={{ maxWidth: '340px' }}>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Номер телефона'
                value={phone}
                onChange={event => {
                  isNumeric(event.target.value) && setPhone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
          </Stack>
          <StatStyled>
            <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
              Условия акции: <br /> Для открытия возможности получения подарка, нужно указать номер подруги, которая еще
              не является клиентом нашей клиники. 🥰 <br />И самое потрясающее в том, что она так же сможет
              воспользоваться данным предложением или вовсе выбрать себе подарок купон на 5000 рублей.
              <br />
            </StatLabel>
          </StatStyled>
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            Для этого укажите
          </Typography.Title>
          <Stack spacing='1rem' alignItems='center'>
            <InputGroup style={{ maxWidth: '340px' }}>
              <Input
                maxLength={30}
                /* isInvalid={error} */
                placeholder='Имя подруги'
                value={friend_name}
                onChange={e => {
                  setFriend_Name(e.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup style={{ maxWidth: '340px' }}>
              <InputLeftAddon children='+7' />
              <Input
                maxLength={10}
                type='tel'
                /* isInvalid={error} */
                placeholder='Номер телефона'
                value={friend_phone}
                onChange={event => {
                  isNumeric(event.target.value) && setFriend_Phone(event?.target.value);
                  setError(false);
                }}
              />
            </InputGroup>
            <InputGroup style={{ maxWidth: '340px' }}>
              <Select
                value={city}
                onChange={event => {
                  setCity(event?.target.value);
                  setError(false);
                }}
                placeholder='Город'
              >
                <option value='Москва'>Москва</option>
                <option value='Санкт-Петербург'>Санкт-Петербург</option>
                <option value='Казань'>Казань</option>
                <option value='Уфа'>Уфа</option>
                <option value='Тверь'>Тверь</option>
              </Select>
            </InputGroup>
            <StatStyled>
              <StatLabel style={{ textAlign: 'center', fontSize: 15 }}>
                ❤️ Рады дарить подарки! Не упускайте лучшую возможность! И будьте прекрасны всегда! <br />
              </StatLabel>
            </StatStyled>
          </Stack>
          {error && (
            <Stack spacing='1rem' style={{ marginTop: 12 }}>
              {errorText && (
                <Alert status='error'>
                  <AlertIcon />
                  {errorText}
                </Alert>
              )}
            </Stack>
          )}
          <Button
            //disabled={city === "" || friend_phone === "" || friend_name === "" || phone === "" || username === ""}
            onClick={() => {
              if (city === '' || friend_phone === '' || friend_name === '' || phone === '' || username === '') {
                setError(true);
                setErrorText(
                  city === ''
                    ? 'Вы не указали город'
                    : friend_phone === ''
                    ? 'Вы не указали номер подруги/друга'
                    : friend_name === ''
                    ? 'Вы не указали имя подруги/друга'
                    : phone === ''
                    ? 'Вы не указали свой номер'
                    : username === ''
                    ? 'Вы не указали своё имя'
                    : null
                );
              } else {
                onSubmit();
              }
            }}
            mt='24px'
            w='100%'
            h='60px'
            alignSelf='center'
            colorScheme='teal'
            variant='solid'
            style={{ marginBottom: '16px', maxWidth: '340px' }}
          >
            Хочу участвовать
            <br />в акции
          </Button>
        </Stack>
      </Box>
    </AppTemplate>
  );
});
